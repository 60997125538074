import '../styles/hero/seven.scss'
import Header from "../components/Header"

const HeroSeven = () => {
  return (
   <>
    <div className="hero__seven__container">
        <div className="hero__seven__wrapper">
            <p className="hero__seven__tag">
                <span>Simple. </span>
                <span>Trading. </span>
                <span>Demistified. </span>
                {/* Trading. Demistified */}
                </p>

            <h1 className="hero__six__text">
                <span> There's</span>
                <span> more</span>
                <span> than</span>
                <span> one</span>
                <span> way</span>
                <span> to</span>
                <span> trade</span>
                </h1>
            <p className="hero__six__supporting__text">Gain access to all major trading markets on a reliable platfom.</p>
            <span className="hero__six__buttons">
                {/* <a href="https://app.epiclivetrading.com">
                <button className="hero__seven__button__white">
                    Learn more
                    </button>
                    </a>
                 */}
                    <a href="https://app.epiclivetrading.com">
                <button className="hero__seven__button__blue">
                Get started
                    </button>
                    </a>
                

            </span>
        </div>
    </div>
   </>
  )
}

export default HeroSeven